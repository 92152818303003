"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
exports.__esModule = true;
exports.parseInputs = void 0;
var react_native_1 = require("react-native");
function parseInputs(inputs) {
    var classNames = [];
    var styles = {};
    inputs.forEach(function (input) {
        if (typeof input === "string") {
            classNames = __spreadArrays(classNames, split(input));
        }
        else if (Array.isArray(input)) {
            classNames = __spreadArrays(classNames, input.flatMap(split));
        }
        else if (typeof input === "object" && input !== null) {
            for (var _i = 0, _a = Object.entries(input); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (typeof value === "boolean") {
                    classNames = __spreadArrays(classNames, (value ? split(key) : []));
                }
                else {
                    styles[key] = value;
                }
            }
        }
    });
    return [classNames.map(accountForPlatform).filter(Boolean).filter(unique), styles];
}
exports.parseInputs = parseInputs;
function split(str) {
    return str.trim().split(/\s+/);
}
function unique(className, index, classes) {
    return classes.indexOf(className) === index;
}
function accountForPlatform(className) {
    return className.replace(/^(ios|android|windows|macos|web):(.*)/, function (_, os, className) {
        return react_native_1.Platform.OS === os ? className : "";
    });
}
